import React from 'react'
import { Link, navigate } from 'gatsby'
import PageHeader from '../components/PageHeader'
import PageHeaderAuthenticated from '../components/PageHeaderAuthenticated'
import PageFooter from '../components/PageFooter'
import * as _h from '../helpers';
import { get, set } from 'lodash/get'

import 'normalize.css'
import '../global.scss'
import styles from "./layout.module.scss"

class Template extends React.Component {
  constructor(props) {
    super(props)

    let authUser = null

    if (typeof sessionStorage !== "undefined") {
      authUser = sessionStorage.getItem('graphiql:sessid') || null
    }

    this.state = {
      authUser: authUser,
      visible: false
    }


  }

  checkAuth = () => {
    const datoCMSAPItoken = this.props.readOnlyAPIToken

    if (_h.PUBLIC_ROUTES.indexOf(this.props.location.pathname) == -1 && !this.state.authUser) {
      if (localStorage.getItem('graphiql:sessid')) {
        fetch(
          'https://graphql.datocms.com/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': `Bearer ${datoCMSAPItoken}`,
            },
            body: JSON.stringify({
              query: '{ jelszavak(filter: {id: {eq: "' + localStorage.getItem('graphiql:sessid') + '"}}){ id }}'
            }),
          }
        )
        .then(res => res.json())
        .then((res) => {
          if (res.data.jelszavak && res.data.jelszavak.id) {
            localStorage.setItem('graphiql:sessid', res.data.jelszavak.id)
            sessionStorage.setItem('graphiql:sessid', res.data.jelszavak.id)
            this.state.authUser = res.data.jelszavak.id
            this.setState({visible: true})
          } else {
            navigate('/welcome?redirectTo=' + this.props.location.pathname);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        navigate('/welcome?redirectTo=' + this.props.location.pathname);
      }
    } else {
      this.setState({visible: true})
    }
  }

  componentDidMount() {
    this.checkAuth()
    setTimeout(() => {window.scrollTo(0,0)}, 0)
  }

  componentDidUpdate() {
    //this.checkAuth()
  }


  render() {
    const { location, children, slogan } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const loginPath = `${__PATH_PREFIX__}/login`
    const welcomePath = `${__PATH_PREFIX__}/welcome`

    let header

    if (location.pathname === welcomePath ) {
      header = null
    } else {
      header = !!this.state.authUser ? <PageHeaderAuthenticated /> : <PageHeader />
    }
    return (
      <div className={styles.container}>
        { this.state.visible &&
          <div>
            {header}
            {children}
            {!!this.state.authUser && location.pathname !== welcomePath && <PageFooter/>}
            </div>}
      </div>
    )
  }
}

export default Template
