import React from 'react'
import { StaticQuery, Link, graphql, navigate } from 'gatsby'
import styles from "./pageheader.module.scss"
import stemLogo from '../assets/stem_logo_black.svg'

class PageHeader extends React.Component {

  render() {
    return (
      <header className={styles.container}>
        <div className={styles.logoBar}>
          <Link to={'/'} className={styles.logo}><img src={stemLogo} alt={`Nyitó oldal`}/></Link>
        </div>
      </header>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsCikkek (sort: {
          fields: kozzeteve
          order: DESC
        } limit: 1){
          edges {
            node {
              kozzeteve
            }
          }
        }
      }
    `}
    render={ (data) => (
      <PageHeader data={data}/>
    )}
  />
)
