import React from 'react'
import { StaticQuery, Link, graphql, navigate } from 'gatsby'
import styles from "./pageheader.module.scss"
import stemLogo from '../assets/stem_logo_black.svg'

class PageHeader extends React.Component {

  doLogout() {
    localStorage.removeItem('graphiql:sessid')
    sessionStorage.removeItem('graphiql:sessid')
    navigate('/welcome')
  }

  doSearch(lastPostDate) {
    navigate('/search?month=' + lastPostDate)
  }

  doShowFavs() {
    navigate('/search?f=true')
  }

  render() {
    const lastPostDateParts = this.props.data.allDatoCmsCikkek.edges[0].node.kozzeteve.split("-")
    const lastPostDate = lastPostDateParts[0] + "" + lastPostDateParts[1]

    return (
      <header className={styles.container}>
        <div className={styles.logoBar}>
          <Link to={'/'} className={styles.logo}><img src={stemLogo} alt={`Nyitó oldal`}/></Link>

            <button onClick={this.doSearch.bind(this, lastPostDate)} className={styles.searchBtn}>
              <i alt="Keresés" data-icon="search"></i>
            </button>

            <button onClick={this.doShowFavs} className={styles.favBtn}>
              <i alt="Kedvencek" data-icon="star"></i>
            </button>

            <button onClick={this.doLogout} className={styles.logoutBtn}>
              <i alt="Kijelentkezés" data-icon="logout"></i>
            </button>
        </div>
      </header>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsCikkek (sort: {
          fields: kozzeteve
          order: DESC
        } limit: 1){
          edges {
            node {
              kozzeteve
            }
          }
        }
      }
    `}
    render={ (data) => (
      <PageHeader data={data}/>
    )}
  />
)
