import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

import styles from "./pagefooter.module.scss"

import stemLogo from '../assets/stem_logo_black.svg'
import tovabbicikkekIcon from '../assets/tovabbicikkek.svg'

class PageFooter extends React.Component {
  render() {
    const slogan = this.props.data.datoCmsFooter.szlogen
    const links = this.props.data.datoCmsFooter.oldalHivatkozasok
    const copyright = this.props.data.datoCmsFooter.copyright
    const lastPostDateParts = this.props.data.allDatoCmsCikkek.edges[0].node.kozzeteve.split("-")
    const lastPostDate = lastPostDateParts[0] + "" + lastPostDateParts[1]

    return (
      <footer className={styles.container}>
        <Link to={'/search?month=' + lastPostDate} className={styles.archiveLink}>
          <img src={tovabbicikkekIcon}/>
          Cikk archívum
        </Link>

        <br/>

        <Link to={'/'} className={styles.logo}><img src={stemLogo} alt={`Nyitó oldal`} /></Link>

        {/*<p>{slogan}</p>*/}

        <nav className={styles.links}>
          {links.map((node, index) => {
            return index == 0
              ? (<span key={node.slug}><Link to={'/page/' + node.slug}>{node.cim}</Link></span>)
              : (<span className={styles.separated} key={node.slug}><Link to={'/page/' + node.slug}>{node.cim}</Link></span>)
          })}
        </nav>
        <p className={styles.copyright}>{copyright}</p>
      </footer>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsFooter {
          szlogen
          oldalHivatkozasok {
            cim
            slug
          }
          copyright
        }
        allDatoCmsCikkek (sort: {
          fields: kozzeteve
          order: DESC
        } limit: 1){
          edges {
            node {
              kozzeteve
            }
          }
        }
      }
    `}
    render={data => (
      <PageFooter data={data}/>
    )}
  />
)
