export const PUBLIC_ROUTES = ['/welcome', '/login', '/page/adatvedelem', '/page/impresszum', '/page/jogi-nyilatkozat']

export const formatDate = date => {
  let parts = date.split('-')
  let months = ['január',
                'február',
                'március',
                'április',
                'május',
                'június',
                'július',
                'augusztus',
                'szeptember',
                'október',
                'november',
                'december']

  return parts[0] + '. ' + months[parseInt(parts[1]) - 1] + (parts[2] ? ' ' + parts[2] + '.': '')
}
